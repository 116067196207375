
* {
  margin: 0%;
  padding: 0%;
}

a {
  text-decoration: none;
}
body {
  margin: 0%;
  padding: 0%;
}

.bg1-primary {
  background-color: black;
}

.this-website {
  font-size: 93px;
  line-height: 0.8;
  font-family: QuadraticBold;
  word-wrap: break-word;
  color: black;
}

.this-website-para {
  font-size: 28px;
  margin-top: 37px;
  font-family: QuadraticRegular;
  word-wrap: break-word;
  margin-left: 2%;
  margin-right: 2%;
  line-height: 1.2;
  color: #59595c;
}

.this-website-para2 {
  font-size: 28px;
  margin-top: 15px;
  font-family: QuadraticRegular;
  word-wrap: break-word;
  margin-left: 2%;
  margin-right: 2%;
  line-height: 1.2;
  color: #59595c;
}

.stay-tuned {
  margin-top: 30px !important;
}

.what-is-meli {
  font-size: 36px ;
  font-family: QuadraticBold;
  margin-right: 17%;
  width: 40%;

}

.text-bg {
  padding-bottom: 0em;
  background-color: #fdf4a1;
}

.carousel-indicators-btn {
  border-radius: 50%;
  height: 12px !important;
  width: 12px !important;
  background-color: rgb(87, 84, 84) !important;
  margin-bottom: -11px;



}

.carousel-item-para {
  height: 200px;
}

/* footer section start */
.flexcontainer {
  display: flex;
  background-color: black;
  color: white;
padding-bottom: 25px;
  flex-direction: column;
  width: 100%;
  font-family: QuadraticRegular;
  justify-content: center;
  align-items: center;
}

.heading {
  font-family: QuadraticBold;
  margin-top: 200px;
  margin-bottom: 90px;
  font-size: 25px;
  text-align: center;
}

.heading3{
  font-family: QuadraticBold;
  margin-top: 200px;
  font-size: 17px;
  text-align: center;
  margin-bottom: 80px;
  margin-top: 350px;
}

.heading1{
  font-family: QuadraticRegular;
  font-size: 17px;
  text-align: center;
}

.appStoreLogo{
  height: 32px;
  width: 104px;
}

.second {
  display: flex;
  margin-top: 120px;
  margin-bottom: 50px;
  padding: 4px 18px;
  font-size: 17px;
  align-items: center;
  justify-items: center;
  font-family: QuadraticRegular;
  /* border: 0.5px solid white;*/
  border-radius: 30px;
  /* box-shadow: 0px 0px 2px white; */
  border: 0;
font-weight: normal;
}

.third {
  margin-top: 100px;
  margin-bottom: 0px;
  font-size: 17px;
  font-family: QuadraticRegular;
}

.fourth img {
  height: 20px;
  width: 90px;
  margin-top: 20px;  
  margin-bottom: 30px;

}

.second img {
  height: 28px;
  /* width: 35px; */

  margin-right: 8px;
  margin-bottom: 4px;
}

@media only screen and (max-width: 600px) {
  .heading {
      display: flex;
      justify-content: center;
      font-size: 17px;
      margin-top: 280px;
  }
  .second {
      display: flex;
      margin-top: 50px;
      margin-bottom: 50px;
      padding: 4px 18px;
      font-size: 13px;
      align-items: center;
      justify-items: center;
      font-family: QuadraticRegular;
      /* border: 0.5px solid white;*/
      border-radius: 30px;
      /* box-shadow: 0px 0px 2px white; */
  
  }
  
  .third {
      margin-top: 100px;
      margin-bottom: 0px;
      font-size: 13px;
      font-family: QuadraticRegular;
  }
  .second img {
      height: 18px;
  }
  .flexcontainer {
      display: flex;
      background-color: black;
      color: white;
  padding-bottom: 40px;
     
  }
  .fourth img {
      height: 20px;
      width: 90px;
      margin-top: 20px;  
      margin-bottom: 30px;
  
  }
}



/* without media query */
.play-btn {
  position: absolute;
  bottom: 9%;
  right: 11%;
  background-color: #000000;
  color: white;
  font-size: 27px;
  padding: 6px 17px;
  /* padding-left: 21px;
  padding-right: 21px; */
  border-radius: 30px;
  border: none;

}

.video-div {
  position: relative;
}

.video-content {
  width: 100%;
  height: 550px;
  padding: 0;
  object-fit: cover;
}

@media only screen and (max-width:997px) {
  .play-btn {
      position: absolute;
      bottom: 40%;
      right: 10px;
      background-color: rgb(0, 0, 0);
      color: white;
      font-size: 15px;
      padding: 8px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 30px;
      border: none;
  }
}

.wrapper {
  width: 100%;
  overflow: hidden;
}

.photobanner {
  position: relative;
  height: 233px;
  margin-bottom: 30px;
  display: flex;
  width: 100%;
}

.photobanner img {
  margin: 0px 10px;
  margin-top: 10px;
  width: 392px;
  height: 235px;
  /* box-shadow: 2px 2px 8px #8a8a8a; */
}

.photobanner {
  animation: bannermove 55s linear infinite;
}

@keyframes bannermove {
  from {
      left: 0px;
  }

  to {
      left: -2700px;
  }
}


@media only screen and (max-width:600px) {
  .this-website {
      font-size: 50px;
      line-height: 1;
      font-family: QuadraticBold;
      word-wrap: break-word;
      color: black;
  }

  .this-website-para {
      font-size: 17px;



  }
  .this-website-para {
      font-size: 19px;
      margin-top: 30px;
      font-family: QuadraticRegular;
      word-wrap: break-word;
      margin-left: 0%;
      margin-right: 0%;
      line-height: 1.2;
      color: #59595c;
  }

  .this-website-para2 {
      font-size: 19px;
      margin-top: 10px;
      font-family: QuadraticRegular;
      word-wrap: break-word;
      margin-left: 2%;
      margin-right: 2%;
      line-height: 1.2;
      color: #59595c;
  }
  
.what-is-meli {
  /* margin-top: -30px; */
  font-size: 20px ;
  font-family: QuadraticBold;
  margin-right: 28%;
  width: 100%;

}
.photobanner {
  position: relative;
  height: 150px;
  margin-bottom: 10px;
  display: flex;
  width: 100%;
}
.photobanner img {
  margin: 0px 10px;
  margin-top: 10px;
  /* min-width:233px;
  min-height:140px;
  max-width:233px;
  max-height: 140px; */
  width: 233px;
  height: 140px;

  
  /* box-shadow: 2px 2px 8px #8a8a8a; */
}
}

.video-content {
  width: 100%;
  height: auto;

}

.play-btn {
  position: absolute;
  bottom: 13%;
  right: 51px;
  background-color: rgb(0, 0, 0);
  color: white;
  font-size: 22px;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 30px;
  border: none;
}

.pause-btn{
  position: absolute;
  bottom: 13%;
  right: 51px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-size: 22px;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 30px;
  border: none;
}
.carousel-item-para {
  height: auto;
}




.carousel-item-para-third p {
  line-height: 1;
}

